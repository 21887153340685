<template>
  <div class="wrap">
    <div class="filter-container">
      <el-radio-group
        class="filter-container--time"
        v-model="filterCondition.time"
        @change="getChartDataList"
      >
        <el-radio-button v-for="item in timeOptions" :label="item.value" :key="item.value">{{
          item.label
        }}</el-radio-button>
      </el-radio-group>

      <div class="filter-container--refresh" @click="getChartDataList">
        <i class="el-icon-refresh"> </i>
      </div>
    </div>

    <div class="chart-panel__container">
      <el-scrollbar>
        <div class="chart-panel-list" v-loading="chartLoading">
          <el-card
            v-for="item in chartDataList"
            class="chart-panel-list__item"
            :key="item.event"
            shadow="hover"
          >
            <MonitorPanel
              :x-axis-data="item.date"
              :series-data="item.value"
              :chart-style="{
                height: '300px',
              }"
              :type="chartTypeConfig[item.event]?.type"
              :time="filterCondition.time"
            >
              <template #header>
                <div class="panel-toolbar">
                  <div class="panel-title">
                    {{ chartTypeConfig[item.event]?.name ?? item.event }}
                  </div>
                  <div class="panel-action">
                    <div class="panel-action--full" @click="handlerFullChart(item.event)">
                      <i class="el-icon-full-screen"></i>
                    </div>
                  </div>
                </div>
              </template>
            </MonitorPanel>
          </el-card>
        </div>
      </el-scrollbar>
    </div>
    <PanelFullModal :visible.sync="chartModalInfo.visible" :type="chartModalInfo.event" />
  </div>
</template>

<script>
  import dayjs from 'dayjs';
  import utc from 'dayjs/plugin/utc';
  import { getChartDataListApi } from '@/api/monitor';

  import MonitorPanel from './components/MonitorPanel.vue';
  import PanelFullModal from './components/PanelFullModal.vue';
  import { chartTypeConfig, timeOptions } from './const';

  dayjs.extend(utc);
  export default {
    components: {
      MonitorPanel,
      PanelFullModal,
    },
    data() {
      return {
        filterCondition: {
          time: '3,hour',
        },
        timeOptions,
        chartTypeConfig,
        data: {
          date: ['1'],
        },
        chartDataList: [],
        chartModalInfo: {
          visible: false,
          event: '',
        },
        chartLoading: false,
      };
    },
    methods: {
      handlerFullChart(type) {
        this.chartModalInfo = {
          visible: true,
          event: type,
        };
      },
      async getChartDataList() {
        try {
          this.chartLoading = true;
          const [time, unit] = this.filterCondition.time.split(',');
          const fromDate = dayjs()
            .utc()
            .subtract(time, unit)
            .format('YYYY-MM-DD HH:mm:ss');
          const toDate = dayjs()
            .utc()
            .format('YYYY-MM-DD HH:mm:ss');
          const { result = [] } = await getChartDataListApi({
            fromDate,
            toDate,
            event: this.type,
            period: 1,
          });
          this.chartDataList = result;
        } catch (error) {
          console.error(error);
        } finally {
          this.chartLoading = false;
        }
      },
    },
    mounted() {
      this.getChartDataList();
    },
  };
</script>
<style lang="scss" scoped>
  .wrap {
    padding: 16px;
    background-color: #f2f3f3;
    height: calc(100vh - 60px);

    .filter-container {
      background-color: #fff;
      display: flex;
      justify-content: flex-end;
      padding: 16px 8px;
      margin-bottom: 16px;
      &--time {
        margin-right: 16px;
      }

      &--refresh {
        cursor: pointer;
        display: inline-block;
        padding: 9px 10px;
        border: 1px solid #dcdfe6;
        border-radius: 6px;
        &:hover {
          border-color: #409eff;
          color: #409eff;
        }
      }
    }
    .chart-panel {
      &__container {
        height: calc(100% - 80px);
      }
      &-list {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        gap: 16px;

        &__item {
          .panel-toolbar {
            display: flex;
            justify-content: space-between;
            .panel-action--full {
              cursor: pointer;
              padding: 8px;
              font-size: 18px;
              margin-right: 16px;
            }
          }
          .panel-title {
            font-size: 24px;
            font-weight: bold;
          }
        }
      }
    }
  }
</style>
