export const periodOptions = [
  {
    value: 1,
    label: '分钟',
  },
  {
    value: 10,
    label: '小时',
  },
  {
    value: 100,
    label: '天',
  },
];

export const timeOptions = [
  {
    label: '1小时',
    value: '1,hour',
  },
  {
    label: '3小时',
    value: '3,hour',
  },
  {
    label: '12小时',
    value: '12,hour',
  },
  {
    label: '1天',
    value: '1,day',
  },
  {
    label: '3天',
    value: '3,day',
  },
  {
    label: '1周',
    value: '1,week',
  },
];

export const chartTypeConfig = {
  clicks: {
    name: '请求数',
    type: 'count',
  },
  cost: {
    name: '延迟',
    type: 'delay',
  },
  200: {
    name: '后端 200',
    type: 'count',
  },
  300: {
    name: '后端 300',
    type: 'count',
  },
  405: {
    name: '后端405',
    type: 'count',
  },
  500: {
    name: '后端500',
    type: 'count',
  },
  connections: {
    name: '连接ei数',
    type: 'count',
  },
  trafficIn: {
    name: '输入流量',
    type: 'count',
  },
  trafficOut: {
    name: '输出流量',
    type: 'count',
  },
};
