<template>
  <div class="monitor-panel" v-loading="loading">
    <div v-if="$slots.header" class="monitor-panel__header">
      <slot name="header"></slot>
    </div>
    <div class="monitor-panel__chart-container" :style="chartStyle">
      <div class="monitor-panel__chart-chart" :style="chartStyle" ref="chartRef"></div>
    </div>
  </div>
</template>

<script>
  import echarts from '@/setup/echarts';

  export default {
    data() {
      return {
        chartInstance: null,
      };
    },
    props: {
      loading: {
        type: Boolean,
        default: false,
      },
      type: {
        // 有三种类型，'delay' 、'count'、'dataFlow'
        type: String,
        default: 'count',
      },
      time: {
        type: String,
        default: '3,hour',
      },
      seriesData: {
        type: Array,
        default: () => [],
      },
      xAxisData: {
        type: Array,
        default: () => [],
      },
      chartStyle: {
        type: Object,
        default: () => ({}),
      },
    },
    computed: {
      chartOptions() {
        const { xAxisData = [], seriesData = [], type = 'count', time } = this;
        return {
          xAxis: {
            type: 'category',
            data: xAxisData,
            axisLabel: {
              formatter: (value) => {
                const [_time, unit] = time.split(',');

                if (unit !== 'hour' || (_time >= 1 && unit === 'day')) {
                  return value?.split(' ')[0];
                } else {
                  const values = value?.split(' ');
                  return values.length < 2 ? values[0] : values[1];
                }
              },
            },
          },
          yAxis: {
            type: 'value',
            name: type === 'dataFlow' ? 'bit/s,5 min avg' : type === 'delay' ? 'Seconds' : 'Count',
            axisLabel: {
              formatter: (value) => {
                if (type == 'dataFlow') {
                  return this.formatStorageLabel(value);
                } else if (type === 'count') {
                  return this.formatCountLabel(value);
                } else {
                  return this.formatDelayLabel(value);
                }
              },
            },
          },
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              animation: false,
            },
            formatter: ['delay', 'dataFlow'].includes(type)
              ? (params) => {
                  params = params[0];
                  console.log(params, 'params');
                  const resultValue =
                    type === 'delay'
                      ? `${this.formatDelayLabel(params.value)} s`
                      : `${params.value.toLocaleString()} bit/s`;

                  return `
                      <div>
                        <p>${params.axisValueLabel}  </p>
                        <p>${params.marker} <span>${resultValue}<span></p>
                      </div>
                    `;
                }
              : null,
          },
          series: [
            {
              data: seriesData,
              type: 'line',
              lineStyle: {
                color: '#588cbc',
              },
              itemStyle: {
                color: '#588cbc',
              },
            },
          ],
        };
      },
    },
    methods: {
      formatStorageLabel(bit) {
        if (isNaN(bit)) {
          return '';
        }
        let symbols = ['bit', 'bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        let exp = Math.floor(Math.log(bit) / Math.log(2));
        if (exp < 1) {
          exp = 0;
        }
        let i = Math.floor(exp / 10);
        bit = bit / Math.pow(2, 10 * i);

        if (bit.toString().length > bit.toFixed(2).toString().length) {
          bit = bit.toFixed(2);
        }
        return bit + ' ' + symbols[i];
      },
      formatCountLabel(num, digits = 2) {
        const si = [
          { value: 1, symbol: '' },
          { value: 1e3, symbol: 'K' },
          { value: 1e6, symbol: 'M' },
          { value: 1e9, symbol: 'G' },
          { value: 1e12, symbol: 'T' },
          { value: 1e15, symbol: 'P' },
          { value: 1e18, symbol: 'E' },
        ];
        const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
        let i;
        for (i = si.length - 1; i > 0; i--) {
          if (num >= si[i].value) {
            break;
          }
        }
        return (num / si[i].value).toFixed(digits).replace(rx, '$1') + si[i].symbol;
      },
      formatDelayLabel(ms, digits = 3) {
        const seconds = (ms / 1000).toFixed(digits);
        return `${seconds}`;
      },
      setOptions() {
        this.chartInstance.setOption(this.chartOptions);
      },
      initChart() {
        this.chartInstance = echarts.init(this.$refs.chartRef);
        this.setOptions();
      },
    },
    mounted() {
      this.initChart();

      window.addEventListener('resize', () => {
        if (!this.chartInstance) {
          return;
        }
        this.chartInstance.resize();
      });
    },
    watch: {
      chartOptions() {
        if (!this.chartInstance) return;
        this.chartInstance.setOption(this.chartOptions);
      },
    },
  };
</script>
<style lang="scss">
  .monitor-panel {
    &__chart {
      width: 100%;
    }
  }
</style>
